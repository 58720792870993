// stecy
// Copyright (C) 2024 Oscar

export function header(header: HTMLElement) {
    let hiddenHeight = window.innerHeight / 4;
    if (window.scrollY <= hiddenHeight) {
        header.classList.add("header_hidden");
    }
    window.addEventListener('scroll', () => {
        if (window.scrollY <= hiddenHeight) {
            if (!header.classList.contains("header_hidden")) {
                header.classList.add("header_hidden");
            }
        } else {
            if (header.classList.contains("header_hidden")) {
                header.classList.remove("header_hidden");
            }
        }
    }, { passive: true });
}
