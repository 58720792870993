// stecy
// Copyright (C) 2024 Oscar

import * as setup from "./setup";
import * as effects from "./effects";

export const about = document.getElementById("about")!;

window.onload = () => {
    document.getElementById("tracker")!.setAttribute("data-beampipe-domain", window.location.hostname);
    document.getElementById("footerYear")!.innerHTML = ((new Date()).getUTCFullYear()).toString();
    setup.header(document.querySelector("header")!);
    effects.parallax(document.getElementById("intro_background")!, -10);
    effects.fade(Array.from(document.getElementById("about")!.querySelectorAll("h1")!), "content_hidden");
    effects.writing(document.getElementById("first_glance")!.querySelector("h2")!, ["¡Aprende Francés con una nativa!", "Apprenez le français avec une native!"]);
    loading_done(document.querySelector("page-loader")!, "loader_hidden", "disableScrolling");
}

export function loading_done(loader: HTMLElement, hidden_class: string, disable_scrolling: string) {
    setTimeout(() => {
        if (window.scrollY == 0) {
            document.querySelector("body")!.classList.add(disable_scrolling);
            loader.classList.add(hidden_class);
            setTimeout(() => {
                loader.hidden = true;
                document.querySelector("body")!.classList.remove(disable_scrolling);
            }, (getComputedStyle(loader).getPropertyValue("--animationDuration").slice(0, -1) as any) as number * 500);
        } else {
            loader.hidden = true;
        }
    }, 200);
}
