// stecy
// Copyright (C) 2024 Oscar

import Typed from "typed.js";
import Rellax from "rellax";

export function fade<T extends Element>(elements: T[], animation: string) {
    const onScreen = new IntersectionObserver((elements) => {
        elements.forEach((element) => {
            if (element.isIntersecting) {
                element.target.classList.remove(animation);
            }
        })
    }, {
        threshold: 0.1
    })
    elements.forEach((element) => {
        element.classList.add(animation);
        onScreen.observe(element)
    })
}

export function writing<T extends Element>(element: T, phrases: string[]) {
    let effect = new Typed(element, {
        strings: phrases,
        typeSpeed: 80,
        loop: true
    });
    new IntersectionObserver((elements) => {
        elements.forEach((element) => {
            if (element.isIntersecting) {
                effect.start()
            } else {
                effect.stop()
            }
        })
    }, {
        threshold: 1.0
    }).observe(element);
}

export function parallax<T extends Element>(element: T, speed: number) {
    Rellax(element, {
        speed: speed,
        center: true,
        round: true,
        vertical: true,
    });
}

